var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _vm.isAdmin && _vm.canUpdateCard
                ? _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("open-edit-modal")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-pen")])],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-list-item-title", { staticClass: "text-center mb-1" }, [
                _vm._v(" " + _vm._s(_vm.userLeave.name) + " "),
                _c("h3", { staticClass: "mt-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.userLeave.remainingBalance.hours
                          ? _vm.userLeave.remainingBalance.hours
                          : "-"
                      ) +
                      " / " +
                      _vm._s(_vm.userLeave.totalBalance.hours) +
                      " " +
                      _vm._s(_vm.$t("models.leave.attributes.units.hours")) +
                      " "
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "mb-5 mt-1" },
                [
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("models.userLeave.attributes.pendingApproval")
                        ) +
                        " "
                    ),
                    _c("b", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.userLeave.pendingApproval.convention) +
                          " (" +
                          _vm._s(_vm.userLeave.pendingApproval.hours) +
                          "hrs) "
                      )
                    ])
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("models.userLeave.attributes.approved")) +
                        " "
                    ),
                    _c("b", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(_vm.userLeave.approved.convention) +
                          " (" +
                          _vm._s(_vm.userLeave.approved.hours) +
                          "hrs)"
                      )
                    ])
                  ]),
                  _c("v-list-item-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("models.userLeave.attributes.rejected")) +
                        " "
                    ),
                    _c("b", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(_vm.userLeave.rejected.convention) +
                          " (" +
                          _vm._s(_vm.userLeave.rejected.hours) +
                          "hrs)"
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("v-list-item-subtitle", { staticClass: "text-right" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.userLeave.startDate) +
                    " - " +
                    _vm._s(_vm.userLeave.endDate) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }